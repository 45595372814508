import './App.css';
import '../src/style/mobileview.css'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from './pages/Home'
import Report from './pages/report';
import Login from './pages/login'
import Analysis from './pages/Analysis';
import Result from './pages/result';
import Mobile_header from './components/mobile_header';
import Mobile_slieds from './pages/mobile_slieds';
import ErrorPage from './pages/404';
const router = createBrowserRouter([
  {
    path: "/",
    element: <Mobile_slieds />,
  },
  {
    path: "/reports",
    element: <Report />,
  },
  {
    path: "/login",
    element: <Login />
  },
  {
    path: '/analysis',
    element: <Analysis/>
  },
  {
    path: '/result',
    element: <Result/>
  },
  {
    path: '/mobile_header',
    element: <Mobile_header/>
  },
  {
    path: '/mobile_slides',
    element:<Mobile_slieds/>
  },
  {
    path: '/404',
    element:<ErrorPage />
  }
]);


function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
