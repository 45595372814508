import React, { useState, useEffect } from "react";
import feat_circle from "../images/feat_circle.png";
import "../style/animation.css";
import "../style/responcive.css";
import "../style/style.css";
import "../style/result_style.css";
import "../style/bootstrap.min.css";
import {FaWhatsapp} from 'react-icons/fa'
import "../style/other.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";
import Header from "../components/header";
import Footer from "../components/footer";
import "@splidejs/react-splide/css/core";
import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";
import { Hourglass } from "react-loader-spinner";
import Mobile_header from "../components/mobile_header";
import { useNavigate } from "react-router-dom";
const Result = () => {
  const location = useLocation();
  const { imagePath } = location.state;
  const { posts } = location.state;
  return (
    <>
      <section className="mainSection overflow-auto">
        <Mobile_header />
        {/* <Header /> */}

        <>
          <div className="result_heading w-100 mt-4">
            {imagePath && (
              <img className="render_image" src={imagePath} alt="Image" />
            )}
            <h5 className="text-center text-uppercase">your result</h5>
            <CircularProgressbar
              className="circle-progress"
              value={posts?.scores?.skin_health}
              text={`${posts?.scores?.skin_health}%`}
            />
          </div>
          <section
            className="result_output_section mt-5"
            style={{ marginBottom: "10%" }}
          >
            <div className="w-100 ps-4 progress_text">
              <span className="text-capitalize fs-7">Texture</span>
              <span className="pe-3">{posts?.scores?.uneven_skin}%</span>
            </div>
            <div className="progressbar">
              <div
                class="progress"
                style={{ height: posts?.scores?.uneven_skin }}
              >
                <div
                  class="progress-bar"
                  style={{
                    width: posts?.scores?.uneven_skin + "%",
                    backgroundColor: posts?.scores?.uneven_skin_hex_color,
                  }}
                ></div>
              </div>
            </div>
            <div className="w-100 ps-4 progress_text">
              <span className="text-capitalize fs-7">Hyperpigmentation</span>
              <span className="pe-3">{posts?.scores?.pigmentation}%</span>
            </div>
            <div className="progressbar">
              <div
                class="progress"
                style={{ height: posts?.scores?.pigmentation }}
              >
                <div
                  class="progress-bar"
                  style={{
                    width: posts?.scores?.pigmentation + "%",
                    backgroundColor: posts?.scores?.pigmentation_hex_color,
                  }}
                ></div>
              </div>
            </div>
            <div className="w-100 ps-4 progress_text">
              <span className="text-capitalize fs-7">Acne</span>
              <span className="pe-3">{posts?.scores?.acne}%</span>
            </div>
            <div className="progressbar">
              <div class="progress" style={{ height: posts?.scores?.acne }}>
                <div
                  class="progress-bar"
                  style={{
                    width: posts?.scores?.acne + "%",
                    backgroundColor: posts?.scores?.acne_hex_color,
                  }}
                ></div>
              </div>
            </div>
            <div className="w-100 ps-4 progress_text">
              <span className="text-capitalize fs-7">Dark Circle</span>
              <span className="pe-3">{posts?.scores?.dark_circle}%</span>
            </div>
            <div className="progressbar">
              <div
                class="progress"
                style={{ height: posts?.scores?.dark_circle }}
              >
                <div
                  class="progress-bar"
                  style={{
                    width: posts?.scores?.dark_circle + "%",
                    backgroundColor: posts?.scores?.dark_circle_hex_color,
                  }}
                ></div>
              </div>
            </div>
            <div className="w-100 ps-4 progress_text">
              <span className="text-capitalize fs-7">Crow's feet</span>
              <span className="pe-3">{posts?.scores?.crows_feet}%</span>
            </div>
            <div className="progressbar">
              <div
                class="progress"
                style={{ height: posts?.scores?.crows_feet }}
              >
                <div
                  class="progress-bar"
                  style={{
                    width: posts?.scores?.crows_feet + "%",
                    backgroundColor: posts?.scores?.crows_feet_hex_color,
                  }}
                ></div>
              </div>
            </div>
            <div className="w-100 ps-4 progress_text">
              <span className="text-capitalize fs-7">Hydration Score</span>
              <span className="pe-3">{posts?.scores?.hydration}%</span>
            </div>
            <div className="progressbar mb-4">
              <div
                class="progress"
                style={{ height: posts?.scores?.hydration }}
              >
                <div
                  class="progress-bar"
                  style={{
                    width: posts?.scores?.hydration + "%",
                    backgroundColor: posts?.scores?.hydration_hex_color,
                  }}
                ></div>
              </div>
            </div>
            <div className="text-center">
              <h4 className="text-center" style={{ color: "#9a7158" }}>
                <strong style={{ color: "#DC143C" }}>Note:- </strong>For further
                consultation and advice
              </h4>
              <a
                href={`https://wa.me/918800685191?text=I Need More Counseling%0a%0aSkin Test Results%0aTexture=${posts?.scores?.uneven_skin}%0aHyperpigmentation=${posts?.scores?.pigmentation}%0aAcne=${posts?.scores?.acne}%0aDarkCircle=${posts?.scores?.dark_circle}%0aCrowFeet=${posts?.scores?.crows_feet}%0aHydrationScore=${posts?.scores?.hydration}`}
              >
                <button
                  type="button"
                  id="sub"
                  className="next_btn whatsApp apply btn-center"
                  style={{
                    borderColor: "#997057",
                    backgroundColor: "#986e55",
                    color: "#fff",
                  }}
                >
                  <FaWhatsapp />
                  WhatsApp Us
                </button>
              </a>
            </div>
          </section>
        </>
        <Footer />
      </section>
    </>
  );
};

export default Result;
