import React from 'react'
import skinfieLogo from '../images/iksana-wellness-logo.png'
import { Link } from 'react-router-dom'
const Home_header = () => {
    return (
        <div className="w-100 sticky-top">
            <nav className="navbar navbar-dark bg-white p-0">
                <div className="container-fluid justify-content-space-between mobile_header_top ">
                    <Link to={'/'}>
                    <img src={skinfieLogo} alt="no show images" style={{ width: "140px" }} />
                    </Link>
                    {/* <button className="navbar-toggler" style={{fontSize:"16px"}} type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button> */}
                </div>
            </nav>
            {/* <div className="collapse" id="navbarToggleExternalContent">
                <div className="p-4 active_link_header">
                    <div className="nav-side">
                        <div className="d-flex align-items-center">
                            <a href={'/analysis'} className="btn rounded-pill brd-gray hover-blue4 sm-butn fw-bold me-2">
                                <span>Take a Test</span>
                            </a>
                            <a href="/reports" className="btn rounded-pill brd-gray hover-blue4 sm-butn fw-bold me-2">
                                <span>Reports</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Home_header