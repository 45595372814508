import React, { useState, useEffect } from "react";
import header_4 from "../images/header_4.png";
import feat_circle from "../images/feat_circle.png";
import Header from "../components/header";
import Footer from "../components/footer";
import Cookies from "universal-cookie";
import "../style/login.css";
import Mobile_header from "../components/mobile_header";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { imagePath } = location.state;
  const [f_name, setf_name] = useState("");
  const [email_id, setemail_id] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [posts, setPosts] = useState([]);
  // console.log("posts",posts)
  const [loading, setLoading] = useState(true);
  const cookies = new Cookies();
  const gender_val = cookies.get("gender_val");
  const select_skin_val = cookies.get("select_skin_val");
  const age_val = cookies.get("age_val");
  const [imagePath1, setImagePath] = useState("");
  useEffect(() => {
    const { imagePath } = location.state;
    if (imagePath) {
      setImagePath(imagePath);
      reportApiCall(imagePath, select_skin_val, age_val, gender_val);
    }
    // }, [location.state]);
  }, [location.state, age_val, gender_val, select_skin_val]);

  const reportApiCall = (imagePath, select_skin_val, age_val, gender_val) => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      image: imagePath,
      skin_type: select_skin_val,
      age: age_val,
      gender: gender_val,
      partner_code: "7777",
    });
    fetch("https://api.skinfie.ai/api/skinanalysis/skin_V4/", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const response_data = data.data;
        // setPosts(response_data); // Set the response data directly
        // setLoading(false);
        if (JSON.stringify(response_data).length == 2) {
          setPosts([]);
          setLoading(true);
          navigate("/404");
        } else {
          setPosts(response_data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitDisabled(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      name: f_name,
      email: email_id,
      phone: phone_number,
      texture: posts?.scores?.uneven_skin,
      hyperpigmentation: posts?.scores?.pigmentation,
      acne: posts?.scores?.acne,
      darkCircle: posts?.scores?.dark_circle,
      crowFeet: posts?.scores?.crows_feet,
      hydrationScore: posts?.scores?.hydration,
    });
    // console.log("raw",raw)
    fetch("https://api.skinfie.ai/api/register/add/", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        const response_data = data.data;
        // console.log("response_data", response_data);
        navigate("/result", { state: { imagePath: imagePath, posts: posts } });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const buttonStyle = {
    backgroundColor: isSubmitDisabled ? 'gray' : '#fa7070', // Change colors as needed
    color: 'white',
    // Add other styling properties as needed
  };

  return (
    <>
      <section className="mainSection overflow-hidden login-section">
        <Mobile_header />
        {loading.toString() == "false" ? (
          <div className="login_page">
            <div className="Login_heding">
              <h5>Your result is ready!</h5>
              <p>Sign in to get your Analysis Report</p>
            </div>
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              className="login_form_section"
            >
              <input
                onChange={(e) => {
                  const input = e.target.value;
                  // Allow input only if it contains no numeric or special characters
                  if (!/[\d!@#$%^&*(),.?":{}|<>]/.test(input)) {
                    // Replace multiple spaces with a single space
                    const sanitizedInput = input.replace(/\s{2,}/g, " ");

                    // Set the state with the sanitized input
                    setf_name(sanitizedInput);
                  }
                }}
                type="text"
                id="f_name"
                value={f_name}
                className="form-control"
                placeholder="Enter Name"
                required
              />

              <input
                onChange={(e) => setemail_id(e.target.value)}
                type="email"
                id="email_id"
                value={email_id}
                className="form-control"
                placeholder="Enter email Id"
                required
              />
              <input
                onChange={(e) => {
                  const input = e.target.value;
                  // Allow input only if it's a number and 10 characters or less
                  if (/^\d{0,10}$/.test(input)) {
                    setphone_number(input);
                  }
                }}
                type="tel"
                id="phone_number"
                value={phone_number}
                className="form-control"
                placeholder="Enter Phone Number"
                required
              />

              <div className="login_submit_btn">
                <input type="submit" disabled={isSubmitDisabled} style={buttonStyle} />
              </div>
            </form>
          </div>
        ) : (
          <div className="justify-content-center">
            <div className="row">
              <div className="col-12">
                <div className="loder">
                  <div className="scan">
                    {/* <h4 style={{color:"#9c735b"}}>Now analysing</h4>
                <h5 style={{color:"#9c735b"}}>Key indicators on your face...</h5> */}
                    <div className="sleceted_gender text-center">
                      <div className="array d-flex">
                        <span>[</span>
                        <div className="fs-5">
                          Now analysing <br />
                          Key indicators on your face....
                        </div>
                        <span>]</span>
                      </div>
                    </div>
                    <div
                      className="face"
                      style={{
                        backgroundImage: `url(${imagePath1})`,
                        backgroundSize: "300px",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        overflow: "hidden",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </section>
    </>
  );
};

export default Login;
