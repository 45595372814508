import React from 'react'
import '../style/footer.css'
import footer_4_wave from '../images/footer_4_wave.png'
const Footer = () => {
  return (
    <footer className=''>
            <div className="footer">
                <p className='text-center text-capitalize' style={{color:"#956c52"}}>p4,Hauz Khas new delhi-110016 phone:+91 8800685191 email: info@iksanawellness.com</p>
                <h5 className='text-center text-capitalize' style={{color:"#956c52"}}>ikasana wellness @ 2023.all rights reserved.</h5>
                <h2 className='text-center' style={{color:"#956c52"}}>powered by ima-appweb.com</h2>
                <a href='https://www.ima-appweb.com/?utm_source=ai.iksanawellness.com&utm_medium=footer&utm_campaign=iksaana+ai+tracking' target="_blank">
                  {/* You can add content inside the link if needed */}
                </a>
            </div>
        </footer>

          )
}

          export default Footer